<template>
	<div>
		<div style="margin-top: -20px;padding-top: 10px;position: sticky;top: -10px;background: #FFF;z-index: 99;">
			<Header :title="title" @back="onBack(false)"></Header>
			<a-tabs @change="onTabChange" style="padding-left: 10px;">
				<a-tab-pane :key="1" tab="基本信息"></a-tab-pane>
				<a-tab-pane :key="6" tab="包含物" v-if="modelRef.type === 5"></a-tab-pane>
				<a-tab-pane :key="2" tab="销售信息"></a-tab-pane>
				<a-tab-pane :key="3" tab="图文描述"></a-tab-pane>
				<a-tab-pane :key="4" tab="物流信息"></a-tab-pane>
				<a-tab-pane :key="5" tab="其他信息"></a-tab-pane>
			</a-tabs>
		</div>
		<a-spin :spinning="loading">
			<div id="content" style="padding-top: 26px;">
				<a-form ref="form" name="form" :model="modelRef" scrollToFirstError :labelCol="{span: 4, xxl: 3}"
						:wrapperCol="{span: 15, xxl: 14 }" @finish="onSubmit">
					<div class="ui-card">
						<h3 id="1" class="ui-card__header">基本信息：</h3>

						<div v-if="type === 2">
							<a-form-item name="organizationId" label="影院组织"
										 :rules="[{ required: true, message: '必选项不允许为空' }]">
								<a-select :disabled="isEdit || isSee" placeholder="请选择" v-model:value="modelRef.organizationId"
										  @change="getAllCinemaList">
									<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
										{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>

							<a-form-item name="cinemaId" label="所属影院"
										 :rules="[{ required: true, message: '必选项不允许为空' }]">
								<a-select :disabled="isEdit || isSee" placeholder="请选择" v-model:value="modelRef.cinemaId"
										  @change="cinemaChange">
									<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
										{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
						</div>

						<a-form-item label="卖品类型" name="type" :rules="[{required: true, message: '必选项不允许为空'}]">
							<a-radio-group :disabled="isSee || isEdit" v-model:value="modelRef.type" button-style="solid">
								<a-radio-button :value="1">普通卖品</a-radio-button>
								<a-radio-button :value="5">卖品套餐</a-radio-button>
							</a-radio-group>
						</a-form-item>

						<a-form-item label="卖品名称" name="mainTitle" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input :disabled="isSee" v-model:value="modelRef.mainTitle" placeholder="请输入卖品名称"></a-input>
						</a-form-item>

						<a-form-item label="卖品标题" name="secondTitle">
							<a-input :disabled="isSee" v-model:value="modelRef.secondTitle" placeholder="请输入卖品标题"></a-input>
						</a-form-item>

						<a-form-item label="卖品分类" name="classifyId" :rules="[{required: true, message: '必选项不允许为空'}]">
							<a-cascader
									:disabled="isSee"
									v-model:value="modelRef.classifyId"
									:options="classifyList"
									:fieldNames="{
									label: 'title',
									value: 'id',
									children: 'children'
								}"
									placeholder="请选择卖品分类"
							/>
						</a-form-item>

						<a-form-item label="卖品标签">
							<labelSelect v-model:value="labelIds" :selectItem="labelList" @change="getLabelList"></labelSelect>
							<div class="ui-label" v-if="labelList.length">
								<a-tag class="ui-label__item" color="green" closable @close="onLabelClose(item)" v-for="(item, index) in labelList">{{ item.title }}</a-tag>
							</div>
						</a-form-item>
					</div>

					<div class="ui-card" v-if="modelRef.type === 5">
						<h3 id="6" class="ui-card__header">包含物</h3>
						<a-form-item label="卖品套餐包含物">
							<snackModal v-if="!isSee" v-model:value="snackIds" :selectItem="snackList" @change="onSnackChange" :id="modelRef.cinemaId" from="addSnack"></snackModal>

							<a-form-item-rest>
								<a-table style="margin-top: 10px;" rowKey="id" :columns="snackColumns" :dataSource="snackList" :pagination="false">
									<template #bodyCell="{ column, record }">
										<template v-if="column.key === 'num'">
											<a-input-number :disabled="isSee" v-model:value="record.num" :min="1" placeholder="请输入"></a-input-number>
										</template>

										<template v-if="column.key === 'action'">
											<a-button :disabled="isSee" type="link" @click="onDelete(record, index)">删除</a-button>
										</template>
									</template>
								</a-table>
							</a-form-item-rest>
						</a-form-item>
					</div>

					<div class="ui-card">
						<h3 id="2" class="ui-card__header">销售信息：</h3>
						<a-row>
							<a-col :span="20">
								<a-row>
									<a-col :span="8">
										<a-form-item label="销售价" name="nowPrice"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-input-number :disabled="isSee" v-model:value="modelRef.nowPrice" placeholder="请输入" :min="0"
															:precision="2" @change="() => {onMaxInput()}"></a-input-number>
										</a-form-item>
									</a-col>
									<a-col :span="8">
										<a-form-item label="库存量" name="stock"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-input-number :disabled="isSee" v-model:value="modelRef.stock" placeholder="请输入" :min="0"
															:precision="0"></a-input-number>
										</a-form-item>
									</a-col>
									<a-col :span="8">
										<a-form-item label="预警库存量" name="stockAlert"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-input-number :disabled="isSee" v-model:value="modelRef.stockAlert" placeholder="请输入"
															:min="0" :precision="0"></a-input-number>
										</a-form-item>
									</a-col>
								</a-row>
							</a-col>
						</a-row>

						<a-row>
							<a-col :span="20">
								<a-row>
									<a-col :span="8">
										<a-form-item label="市场价" name="originPrice"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-input-number :disabled="isSee" v-model:value="modelRef.originPrice" placeholder="请输入"
															:min="0" :precision="2"></a-input-number>
										</a-form-item>
									</a-col>
									<a-col :span="8">
										<a-form-item label="限购量" name="maxBuyNum" extra="0为不限购"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-input-number :disabled="isSee" v-model:value="modelRef.maxBuyNum" placeholder="请输入"
															:min="0" :precision="0"></a-input-number>
										</a-form-item>
									</a-col>
									<a-col :span="8">
										<a-form-item label="基础销量" name="virtualSoldNum"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-input-number :disabled="isSee" v-model:value="modelRef.virtualSoldNum" placeholder="请输入"
															:min="0" :precision="0"></a-input-number>
										</a-form-item>
									</a-col>
								</a-row>
							</a-col>
						</a-row>

						<a-row>
							<a-col :span="20">
								<a-row>
									<a-col :span="8">
										<a-form-item label="成本价" name="costPrice"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-input-number :disabled="isSee" v-model:value="modelRef.costPrice" placeholder="请输入"
															:min="0" :precision="2"></a-input-number>
										</a-form-item>
									</a-col>
									<a-col :span="8">
										<a-form-item label="排序号" name="sort" extra="数字越大，排序越前，如果同级，最新创建的优先级大"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-input-number :disabled="isSee" v-model:value="modelRef.sort" placeholder="请输入" :min="0"
															:precision="0"></a-input-number>
										</a-form-item>
									</a-col>
								</a-row>
							</a-col>
						</a-row>

						<div v-if="type === 2">
							<a-spin :spinning="cardLoading">
								<a-form-item label="是否含有卡" name="isCard" :rules="[{required: true, message: '必选项不允许为空'}]">
									<a-radio-group :disabled="isSee" v-model:value="modelRef.isCard">
										<a-radio :value="1">是</a-radio>
										<a-radio :value="0">否</a-radio>
									</a-radio-group>
								</a-form-item>

								<a-row v-if="modelRef.isCard">
									<a-col :span="20">
										<a-row>
											<a-col :span="8">
												<a-form-item label="卡类型" name="cardType" :rules="[{required: true, message: '必选项不允许为空'}]"
															 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
													<a-select :disabled="isSee" v-model:value="modelRef.cardType" placeholder="请选择" @change="onCardChange">
														<a-select-option :value="1">次卡</a-select-option>
														<!-- <a-select-option :value="2">年卡</a-select-option> -->
													</a-select>
												</a-form-item>
											</a-col>

											<a-col :span="8" v-if="modelRef.cardType === 1">
												<a-form-item label="卡次数" name="cardBalanceTimes" :rules="[{required: true, message: '必填项不允许为空'}]"
															 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
													<a-input-number :disabled="isSee" v-model:value="modelRef.cardBalanceTimes" placeholder="请输入" :min="0" :precision="0">
													</a-input-number>
												</a-form-item>
											</a-col>
										</a-row>
									</a-col>
								</a-row>

								<a-form-item v-if="modelRef.isCard" label="卡等级" name="cardId" :rules="[{required: true, message: '必选项不允许为空'}]">
									<a-select style="width: 300px;" :disabled="isSee" v-model:value="modelRef.cardId" placeholder="请选择">
										<a-select-option v-for="item in cardList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
									</a-select>
									<a-button style="margin-left: 10px;" type="primary" shape="circle" @click="getCardList">
										<template #icon>
											<Icon icon="RedoOutlined"></Icon>
										</template>
									</a-button>
								</a-form-item>
							</a-spin>

							<div>
								<a-form-item label="是否含有券" name="isCoupon" :rules="[{ required: true, message: '必选项不允许为空' }]">
									<a-radio-group :disabled="isSee" v-model:value="modelRef.isCoupon">
										<a-radio :value="1">是</a-radio>
										<a-radio :value="0">否</a-radio>
									</a-radio-group>
								</a-form-item>

								<div v-if="modelRef.isCoupon">
									<a-form-item name="ticketBatchNum" label="兑换券发放数量">
										<a-input-number v-model:value="modelRef.ticketBatchNum" :min="0" :disabled="isSee" :precision="0" placeholder="请输入"></a-input-number> 张
									</a-form-item>
									<a-form-item label="兑换券批次" :labelCol="{span: 4, xxl: 3}"
												 :wrapperCol="{span: 20, xxl: 21 }">
										<couponRecord v-model:value="modelRef.ticketBatchId" :id="modelRef.cinemaId" :couponType="1" :selectItem="exchangeList" :disabled="isSee" from="snack" @change="(data) => onCouponChange(1, data)"></couponRecord>
										<div v-show="modelRef.ticketBatchId.length">
											<a-table rowKey="id" :columns="exchangeColumns" :dataSource="exchangeList" :pagination="false" :scroll="{ x: 1200 }">
												<template #bodyCell="{ column, record }">
													<template v-if="column.key === 'createdTime'">
														{{ transDateTime(record.createTime) }}
													</template>

													<template v-if="column.key === 'time'">
														<div v-if="record.validPeriodType == 7">
															领取后{{ record.period }}天内有效
														</div>
														<div v-else>
															{{ transDateTime(record.receiveStartTime, 1) }} - {{ transDateTime(record.receiveEndTime, 1) }}
														</div>
													</template>

													<template v-if="column.key === 'redeemedCount'">
														{{ record.importCount - record.redeemedCount }}
													</template>

													<template v-if="column.key === 'isHoliday'">
														<div v-if="record.isHoliday">通兑</div>
														<div v-else>非通兑</div>
													</template>

													<template v-if="column.key === 'action'">
														<a-button :disabled="isSee" type="line" @click="onDelCoupon(1, index)">删除</a-button>
													</template>
												</template>
											</a-table>
										</div>
									</a-form-item>

									<a-form-item name="snackBatchNum" label="卖品券发放数量">
										<a-input-number v-model:value="modelRef.snackBatchNum" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number> 张
									</a-form-item>
									<a-form-item label="卖品券批次" :labelCol="{span: 4, xxl: 3}"
												 :wrapperCol="{span: 20, xxl: 21 }">
										<couponRecord v-model:value="modelRef.snackBatchId" :couponType="3" :id="modelRef.cinemaId" :selectItem="snackList" :disabled="isSee" from="snack" @change="(data) => onCouponChange(3, data)"></couponRecord>
										<div v-show="modelRef.snackBatchId.length">
											<a-table rowKey="id" :columns="snackBatchColumns" :dataSource="snackList" :pagination="false" :scroll="{ x: 1000 }">
												<template #bodyCell="{ column, record }">
													<template v-if="column.key === 'createdTime'">
														{{ transDateTime(record.createTime) }}
													</template>

													<template v-if="column.key === 'time'">
														<div v-if="record.validPeriodType == 7">
															领取后{{ record.period }}天内有效
														</div>
														<div v-else>
															{{ transDateTime(record.receiveStartTime, 1) }} - {{ transDateTime(record.receiveEndTime, 1) }}
														</div>
													</template>

													<template v-if="column.key === 'redeemedCount'">
														{{ record.importCount - record.redeemedCount }}
													</template>

													<template v-if="column.key === 'action'">
														<a-button :disabled="isSee" type="line" @click="onDelCoupon(3, index)">删除</a-button>
													</template>
												</template>
											</a-table>
										</div>
									</a-form-item>
								</div>
							</div>
						</div>
					</div>

					<div class="ui-card">
						<h3 id="3" class="ui-card__header">图文描述：</h3>
						<a-form-item label="卖品图片" name="imgUrl" extra="卖品图片只能上传1张，大小不能超过2MB"
									 :rules="[{required: true, message: '请上传一张图片'}]">
							<a-upload :disabled="isSee" v-model:file-list="fileList" name="file" list-type="picture-card"
									  class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
									  @change="(file) => handleChange(file, 1)"
									  @remove="(info) => onDelUpload(1, info)"
							>
								<div v-if="fileList.length < 1">
									<Icon icon="PlusOutlined"></Icon>
									<div class="ant-upload-text">上传</div>
								</div>
							</a-upload>
						</a-form-item>

						<a-form-item label="分享海报背景图" name="backgroundImg" extra="卖品分享海报背景图片只能上传1张，大小不能超过2MB，建议宽高比：4:6.9">
							<a-upload :disabled="isSee" v-model:file-list="shareFileList" name="file" list-type="picture-card"
									  class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
									  @change="(file) => handleChange(file, 2)"
									  @remove="onDelUpload(3)"
									  >
								<div v-if="shareFileList.length < 1">
									<Icon icon="PlusOutlined"></Icon>
									<div class="ant-upload-text">上传</div>
								</div>
							</a-upload>
						</a-form-item>

						<a-form-item label="卖品详情" name="detail">
							<div style="min-width: 700px;position: relative;z-index: 9;">
								<Editor v-model:value="modelRef.detail" :configSetting="{showFullScreen: false, disabled: isSee}"></Editor>
							</div>
						</a-form-item>

						<a-form-item label="规格参数" name="specificationInfo">
							<div style="min-width: 700px;position: relative;z-index: 9;">
								<Editor v-model:value="modelRef.specificationInfo" :configSetting="{showFullScreen: false, disabled: isSee}"></Editor>
							</div>
						</a-form-item>

						<a-form-item label="购买须知" name="purchaseNotes">
							<div style="min-width: 700px;position: relative;z-index: 9;">
								<Editor v-model:value="modelRef.purchaseNotes" :configSetting="{showFullScreen: false, disabled: isSee}"></Editor>
							</div>
						</a-form-item>
					</div>

					<div class="ui-card">
						<h3 id="4" class="ui-card__header">物流信息：</h3>

						<a-form-item label="配送方式" name="deliveryType" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-radio-group :disabled="isSee" v-model:value="modelRef.deliveryType" button-style="solid">
								<a-radio-button :value="2">自提</a-radio-button>
							</a-radio-group>
						</a-form-item>
					</div>

					<div class="ui-card">
						<h3 id="5" class="ui-card__header">其它信息：</h3>
						<a-row>
							<a-col :span="20">
								<a-row>
									<a-col :span="8">
										<a-form-item label="是否显示现有库存" name="isStock"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-switch :disabled="isSee" v-model:checked="modelRef.isStock" checked-children="是"
													  un-checked-children="否"></a-switch>
										</a-form-item>
									</a-col>
									<a-col :span="8" v-if="type === 2">
										<a-form-item label="是否允许分享" name="isShare"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-switch :disabled="isSee" v-model:checked="modelRef.isShare" checked-children="是"
													  un-checked-children="否"></a-switch>
										</a-form-item>
									</a-col>
									<a-col :span="8" v-if="type === 2">
										<a-form-item label="是否显示在小程序" name="isShow"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-switch :disabled="isSee" v-model:checked="modelRef.isShow" checked-children="是"
													  un-checked-children="否"></a-switch>
										</a-form-item>
									</a-col>
								</a-row>
								<a-row>
									<a-col :span="8" v-if="type === 2">
										<a-form-item label="是否购票推广" name="isHot"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-switch :disabled="isSee" v-model:checked="modelRef.isHot" checked-children="是"
													  un-checked-children="否" @change="modelRef.hotPrice = undefined"></a-switch>
										</a-form-item>
									</a-col>
									<a-col :span="8" v-if="type === 2">
										<a-form-item label="是否允许第三方核销" name="isThirdVerify"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-switch :disabled="isSee" v-model:checked="modelRef.isThirdVerify" checked-children="是"
													  un-checked-children="否"></a-switch>
										</a-form-item>
									</a-col>
									<a-col :span="8" v-if="type === 2">
										<a-form-item label="是否参与会员卡折扣" name="isCardDiscount"
													 :rules="[{required: true, message: '必填项不允许为空'}]"
													 :labelCol="{span: 14, xxl: 11}" :wrapperCol="{span: 10, xxl: 13 }"
										>
											<a-switch :disabled="isSee" v-model:checked="modelRef.isCardDiscount" checked-children="是"
													  un-checked-children="否"></a-switch>
										</a-form-item>
									</a-col>
								</a-row>
								<a-row>
									<a-col :span="12" v-if="type === 2 && modelRef.isHot">
										<a-form-item label="热销价格" name="hotPrice"
													extra="小程序确认订单页的购买价格,不填则与上方销售价格一致"
													 :labelCol="{span: 8, xxl: 7}" :wrapperCol="{span: 10, xxl: 13 }">
											<a-input-number v-model:value="modelRef.hotPrice" placeholder="请输入" :min="0" :precision="2"></a-input-number> 元
										</a-form-item>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
					</div>

					<div class="ui-card" v-if="type === 2">
						<h3 id="6" class="ui-card__header">分销设置：</h3>

						<a-form-item label="是否参与分销" name="isDistribution"
									 :rules="[{required: true, message: '必填项不允许为空'}]"
						>
							<a-radio-group :disabled="isSee" v-model:value="modelRef.isDistribution">
								<a-radio :value="1">参与</a-radio>
								<a-radio :value="0">不参与</a-radio>
							</a-radio-group>
						</a-form-item>

						<a-form-item v-if="modelRef.isDistribution" label="是否开启独立分销" name="isIndependent"
									 :rules="[{required: true, message: '必填项不允许为空'}]"
						>
							<a-radio-group :disabled="isSee" v-model:value="modelRef.isIndependent">
								<a-radio :value="1">开启</a-radio>
								<a-radio :value="0">关闭</a-radio>
							</a-radio-group>
						</a-form-item>

						<a-form-item v-if="modelRef.isDistribution && modelRef.isIndependent" label="分销配置" name="goodsDistributionConfigType"
									 :rules="[{required: true, message: '必填项不允许为空'}]"
						>
							<a-radio-group :disabled="isSee" v-model:value="modelRef.goodsDistributionConfigType">
								<a-radio :value="1">分销比例</a-radio>
								<a-radio :value="2">分销奖金</a-radio>
							</a-radio-group>
						</a-form-item>

						<div v-if="modelRef.isDistribution && modelRef.isIndependent">
							<a-row>
								<a-col :offset="2" :span="16">
									<a-table v-if="modelRef.goodsDistributionConfigType === 1" rowKey="id" :dataSource="list" :columns="columnsScale" :pagination="false">
										<template #bodyCell="{column, record, index}">
											<template v-if="column.key === 'oneLevelScale'">
												<a-input-number :disabled="isSee" v-model:value="record.oneLevelScale" :min="0" :max="onMaxScoreInput(index, 'oneLevelScale')"></a-input-number> %
											</template>
											<template v-if="column.key === 'twoLevelScale'">
												<a-input-number :disabled="isSee" v-model:value="record.twoLevelScale" :min="0" :max="onMaxScoreInput(index, 'twoLevelScale')"></a-input-number> %
											</template>
											<template v-if="column.key === 'storekeeperLevelScale'">
												<a-input-number :disabled="isSee" v-model:value="record.storekeeperLevelScale" :precision="3" :min="0" :max="onMaxScoreInput(index, 'storekeeperLevelScale')"></a-input-number> %
											</template>
										</template>
									</a-table>
									<a-table v-if="modelRef.goodsDistributionConfigType === 2" rowKey="id" :dataSource="list" :columns="columns" :pagination="false">
										<template #bodyCell="{column, record, index}">
											<template v-if="column.key === 'oneMoneyReward'">
												<a-input-number :disabled="isSee" v-model:value="record.oneLevelBonus" :min="0" :max="onMaxInput(index, 'oneLevelBonus')"></a-input-number> 元
											</template>
											<template v-if="column.key === 'twoMoneyReward'">
												<a-input-number :disabled="isSee" v-model:value="record.twoLevelBonus" :min="0" :max="onMaxInput(index, 'twoLevelBonus')"></a-input-number> 元
											</template>
											<template v-if="column.key === 'storekeeperLevelBonus'">
												<a-input-number :disabled="isSee" v-model:value="record.storekeeperLevelBonus" :precision="2" :min="0" :max="onMaxInput(index, 'storekeeperLevelBonus')"></a-input-number> 元
											</template>
										</template>
									</a-table>
								</a-col>
							</a-row>
						</div>
					</div>

					<div style="margin-top: 30px;text-align: center;">
						<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</div>
				</a-form>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import Editor from '@/components/editor/editor.vue';
	import snackModal from '@/components/snackSelect/index.vue';
	import labelSelect from '@/components/labelSelect/index.vue';
	import couponRecord from '@/components/couponRecord/index.vue';
	import { getCardPolicyList } from '@/service/modules/coupon.js';
	import { getDistributionLevelList } from '@/service/modules/distribution.js';
	import {
		getOrganizationList,
		getCinemaList
	} from '@/service/modules/cinema.js';
	import {
		getGoodsAllClassifyList
	} from '@/service/modules/mall.js';
	import {
		saveGoods,
		updateGoods,
		getGoodsDetail,
		getStoreGoodsDetail,
		saveStoreGoods,
		updateStoreGoods,
    saveGoodsWithoutBaseGoods
	} from '@/service/modules/goods.js';
	export default {
		components: {
			Icon,
			Header,
			Editor,
			labelSelect,
			snackModal,
			couponRecord
		},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			id: {
				type: null,
				default: 0
			},
			type: { // 1平台操作  2上架商城
				type: Number,
				default: 1
			},
			form: {
				type: String,
				default: 'template'
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				loading: false,
				cardLoading: false,
				title: '',
				organizationList: [],
				cinemaAllList: [],
				cardList: [],
				fileList: [],
				shareFileList: [],
				videoList: [],
				labelIds: [],
				labelList: [],
				modelRef: {
					type: 1,
					deliveryType: 2,
					isCard: 0,
					imgUrl: [],
					backgroundImg: [],
					isShow: true,
					isShare: true,
					isStock: false,
					isHot: false,
					isThirdVerify: false,
					isCardDiscount: false,
					isDistribution: 0,
					isIndependent: 0,
					goodsDistributionConfigType: 1,
					isCoupon: 0,
					ticketBatchNum: 0,
					snackBatchNum: 0,
					ticketBatchId: [],
					snackBatchId: []
				},
				list: [],
				columns: [{
					title: '等级名称',
					dataIndex: 'title'
				}, {
					title: '自购分销奖金',
					key: 'oneMoneyReward'
				}, {
					title: '邀请人分销奖金',
					key: 'twoMoneyReward'
				}, {
					title: '团队长分红',
					key: 'storekeeperLevelBonus'
				}],
				columnsScale: [{
					title: '等级名称',
					dataIndex: 'title'
				}, {
					title: '自购分销比例',
					key: 'oneLevelScale'
				}, {
					title: '邀请人分销比例',
					key: 'twoLevelScale'
				}, {
					title: '团队长分红比例',
					key: 'storekeeperLevelScale'
				}],
				snackIds: [],
				snackColumns: [{
					title: '卖品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '售卖数量',
					key: 'num'
				}, {
					title: '操作',
					key: 'action'
				}],
				showSnackModal: false,
				classifyList: [],
				exchangeList: [],
				exchangeColumns: [{
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '批次名称',
					dataIndex: 'name'
				}, {
					title: '有效期',
					key: 'time'
				}, {
					title: '是否通兑',
					key: 'isHoliday',
					width: 100
				}, {
					title: '补差金额（元）',
					dataIndex: 'diffPrice',
					width: 130
				}, {
					title: '兑换券数量',
					dataIndex: 'importCount',
					width: 130
				}, {
					title: '已绑定数量',
					dataIndex: 'bindCount',
					width: 130
				}, {
					title: '未兑换数量',
					key: 'redeemedCount',
					width: 130
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 100
				}],
				snackList: [],
				snackBatchColumns: [{
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '有效期',
					key: 'time'
				}, {
					title: '卖品券数量',
					dataIndex: 'importCount',
					width: 130
				}, {
					title: '已绑定数量',
					dataIndex: 'bindCount',
					width: 130
				}, {
					title: '未兑换数量',
					key: 'redeemedCount',
					width: 130
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 100
				}]
			}
		},
		created() {
			if (this.type === 1) {
				if (this.isEdit) {
					this.title = '编辑卖品';
				} else {
					this.title = '添加卖品';
				}
			} else {
        if (this.isEdit) {
          this.title = '编辑卖品';
        } else if (this.isSee) {
          this.title = '查看卖品';
        } else {
          this.title = '新增卖品';
        }
				// this.snackColumns.splice(2, 1);
				this.getOrganizationList();
			}
			if (this.id) {
				this.getData();
			}
			this.getGoodsAllClassifyList();
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));

				if (postData.originPrice < postData.nowPrice) {
					return this.$message.warn('卖品市场价不能小于卖品销售价，请重新输入')
				}
				if (postData.nowPrice < postData.costPrice) {
					return this.$message.warn('卖品销售价不能小于卖品成本价，请重新输入')
				}
				if (postData.isCoupon) {
					if (!postData.ticketBatchNum && !postData.snackBatchNum) {
						return this.$message.warn('请输入含有券发放数量');
					}
					if (postData.ticketBatchNum && !postData.ticketBatchId.length) {
						return this.$message.warn('请选择兑换券批次');
					}
					if (!postData.ticketBatchNum && postData.ticketBatchId.length) {
						return this.$message.warn('请输入兑换券发放数量');
					}
					if (postData.snackBatchNum && !postData.snackBatchId.length) {
						return this.$message.warn('请选择卖品券批次');
					}
					if (!postData.snackBatchNum && postData.snackBatchId.length) {
						return this.$message.warn('请输入卖品券发放数量');
					}
				}
				if (postData.isCoupon) {
					postData.ticketBatchId = postData.ticketBatchId.length ? postData.ticketBatchId[0] : 0;
					postData.snackBatchId = postData.snackBatchId.length ? postData.snackBatchId[0] : 0;
				} else {
					postData.ticketBatchId = 0;
					postData.snackBatchId = 0;
					postData.ticketBatchNum = 0;
					postData.snackBatchNum = 0;
				}
				postData.mainTitle = postData.mainTitle.replace(/\s*/g, '');
				postData.saleType = 2;
				postData.imgUrl = postData.imgUrl.join(',');
				postData.backgroundImg = postData.backgroundImg.join(',');
				postData.isShow = postData.isShow ? 1 : 0;
				postData.isShare = postData.isShare ? 1 : 0;
				postData.isStock = postData.isStock ? 1 : 0;
				postData.isHot = postData.isHot ? 1 : 0;
				postData.isThirdVerify = postData.isThirdVerify ? 1 : 0;
				postData.isCardDiscount = postData.isCardDiscount ? 1 : 0;
				postData.classifyId = postData.classifyId[postData.classifyId.length - 1];
				if (this.labelIds.length) {
					postData.tagIds = this.labelIds.join(',');
				}
				if (postData.type === 5) {
					if (this.snackList.length === 0) {
						return this.$message.warn('请选择卖品套餐包含物');
					}
					let title = '';
					postData.packageGoodsConfigList = [];
					this.snackList.forEach(item => {
						if (!item.num || item.num <= 0) {
							title = item.mainTitle;
						}
						postData.packageGoodsConfigList.push({
							generalGoodsId: item.id,
							num: item.num,
							id: item.serviceId || undefined
						});
					});
					if (title) {
						return this.$message.warn('卖品套餐包含物中【'+ title +'】售卖数量不正确，请重新输入');
					}
				}
				let ret;
				this.loading = true;
				try {
					if (this.type === 1) {
						if (this.isEdit && this.id) {
							postData.id = this.id;
							ret = await updateGoods(postData);
						} else {
							ret = await saveGoods(postData);
						}
					} else {
						if (postData.isIndependent) {	// 开启独立分销
							let canSubmit = true;
							postData.goodsDistributionConfig = this.list.map((item, index) => {
								if (postData.goodsDistributionConfigType === 1) {
									// this.onMaxInput()
									if (this.onMaxScoreInput(index) < 0) {
										canSubmit = false;
									}
								}
								if (postData.goodsDistributionConfigType === 2) {
									if (this.onMaxInput(index) < 0) {
										canSubmit = false;
									}
								}
								return {
									type: this.modelRef.goodsDistributionConfigType,
									distributionId: item.distributionId ? item.distributionId : item.id,
									goodsId: item.goodsId || undefined,
									oneLevelBonus: this.modelRef.goodsDistributionConfigType === 2 ? item.oneLevelBonus : 0,
									twoLevelBonus: this.modelRef.goodsDistributionConfigType === 2 ? item.twoLevelBonus : 0,
									storekeeperLevelBonus: this.modelRef.goodsDistributionConfigType === 2 ? item.storekeeperLevelBonus : 0,
									oneLevelScale: this.modelRef.goodsDistributionConfigType === 1 ? item.oneLevelScale : 0,
									twoLevelScale: this.modelRef.goodsDistributionConfigType === 1 ? item.twoLevelScale : 0,
									storekeeperLevelScale: this.modelRef.goodsDistributionConfigType === 1 ? item.storekeeperLevelScale : 0,
								}
							});
							if (postData.goodsDistributionConfigType === 2 && !canSubmit) {
								return this.$message.warn('分销设置奖金总金额不得大于卖品销售价');
							}
							if(postData.goodsDistributionConfigType === 1 && !canSubmit) {
								return this.$message.warn('分销比例之和不得大于99%');
							}
						} else {
							postData.goodsDistributionConfig = [];
						}
						if (this.isEdit && this.id) {
							postData.id = this.id;
							ret = await updateStoreGoods(postData);
						} else {
							// postData.goodsId = this.id;
							ret = await saveGoodsWithoutBaseGoods(postData);
						}
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.onBack(true);
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret;
					if (this.type === 1 || this.form === 'template') {
						ret = await getGoodsDetail({
							id: this.id
						});
					} else {
						ret = await getStoreGoodsDetail({
							id: this.id
						});
					}
					this.loading = false;
					if (ret.code === 200) {
						this.labelList = ret.data.tagList.map(item=> {
							return {
								title: item.tagTitle,
								id: item.tagId
							}
						}) || [];
						this.labelIds = this.labelList.map(item => {
							return Number(item.id);
						});
						if (ret.data.packageGoodsConfigList) {
							ret.data.packageGoodsConfigList.forEach(item => {
								this.snackIds.push(item.generalGoodsId);
								this.snackList.push({
									id: item.generalGoodsId,
									mainTitle: item.generalGoodsTitle,
									num: item.num,
									serviceId: item.id
								})
							})
						};

						let data = ret.data.goods;
						if (this.type === 2 && this.form !== 'template') {
							data = ret.data.storeGoodsStock;
						}
						if (ret.data.goodsDistributionConfigList && ret.data.goodsDistributionConfigList.length) {
							data.goodsDistributionConfigType = ret.data.goodsDistributionConfigList[0].type;
							this.list = ret.data.goodsDistributionConfigList;
						} else {
							data.goodsDistributionConfigType = 1;
							// this.getDistributionLevelList();
						}
						this.getDistributionLevelList();
						if (data.organizationId) {
							this.getAllCinemaList(data.organizationId, false);
						}
						data.imgUrl = data.imgUrl.split(',');
						if (data.backgroundImg) {
							data.backgroundImg = data.backgroundImg.split(',');
						} else {
							data.backgroundImg = [];
						}
						if (this.form === 'template') {
							data.isShow = true;
							data.isShare = true;
						} else {
							data.isShow = data.isShow ? true : false;
							data.isShare = data.isShare ? true :false;
						}
						if (data.ticketBatch) {
							this.exchangeList = [{...data.ticketBatch}];
						}
						if (data.snackBatch) {
							this.snackList = [{...data.snackBatch}];
						}
						delete data.snackBatch;
						delete data.ticketBatch;
						data.ticketBatchId = data.ticketBatchId ? [data.ticketBatchId] : [];
						data.snackBatchId = data.snackBatchId ? [data.snackBatchId] : [];
						data.isStock = data.isStock ? true : false;
						data.isThirdVerify = data.isThirdVerify ? true : false;
						data.isCardDiscount = data.isCardDiscount ? true : false;
						data.isHot = data.isHot ? true : false;
						delete data.id;
						data.imgUrl.forEach((item, index) => {
							this.fileList.push({
								uid: index,
								name: item,
								url: item
							})
						});
						if (data.backgroundImg.length) {
							data.backgroundImg.forEach((item, index) => {
								this.shareFileList.push({
									uid: index,
									name: item,
									url: item
								})
							});
						}
						if (data.allClassifyId) {
							data.classifyId = data.allClassifyId.split(',').map(item => Number(item));
						}
						this.modelRef = data;

						if (data.isCard) {
							this.getCardList();
						}
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId, isRef) {
				if (!isRef) {
					this.cinemaAllList = [];
					this.modelRef.cinemaId = undefined;
					this.cinemaChange();
				}
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async getGoodsAllClassifyList() {
				let res = await getGoodsAllClassifyList({
					classifyType: 2
				});
				if (res.code === 200) {
					let classifyList = res.data.filter(item => {
						item.children = res.data.filter(subItem => {
							return subItem.parentId === item.id;
						});
						return !item.parentId
					});
					this.classifyList = classifyList;
					this.transClassify();
				}
			},
			async getCardList() {
				this.cardLoading = true;
				try {
					let ret = await getCardPolicyList({
						page: 1,
						pageSize: 99999,
						organizationId: this.modelRef.organizationId,
						cinemaId: this.modelRef.cinemaId,
						type: this.modelRef.cardType,
						isDisabled: 0
					});
					this.cardLoading = false;
					if (ret.code === 200) {
						this.cardList = ret.data.list;
					}
				} catch(e) {
					this.cardLoading = false;
				}
			},
			async getDistributionLevelList() {
				this.distributionLevelLoading = true;
				try {
					let ret = await getDistributionLevelList({
						page: 1,
						pageSize: 999
					});
					this.distributionLevelLoading = false;
					if (ret.code === 200) {
						ret.data.list.forEach(item => {
							item.oneLevelBonus = 0;
							item.twoLevelBonus = 0;
							item.oneLevelScale = 0;
							item.twoLevelScale = 0;
							item.storekeeperLevelScale = 0;
							item.storekeeperLevelBonus = 0;
						})
						let list = ret.data.list;
						if (this.list.length) {
							list.forEach(item => {
								this.list.forEach(subItem => {
									if(subItem.distributionId === item.id) {
										item.oneLevelBonus = subItem.oneLevelBonus;
										item.twoLevelBonus = subItem.twoLevelBonus;
										item.oneLevelScale = subItem.oneLevelScale;
										item.twoLevelScale = subItem.twoLevelScale;
										item.storekeeperLevelScale = subItem.storekeeperLevelScale;
										item.storekeeperLevelBonus = subItem.storekeeperLevelBonus;
									}
								})
							})
						}
						this.list = list;
					}
				} catch(e) {
					this.distributionLevelLoading = false;
				}
			},
			getLabelList(data) {
				this.labelList = JSON.parse(JSON.stringify(data));
			},
			onLabelClose(item, index) {
				this.labelList.splice(index, 1);
				this.labelIds.splice(this.labelIds.indexOf(item.id), 1);
			},
			cinemaChange() {
				this.cardList = [];
				// this.modelRef.isCard = 0;
				// this.modelRef.cardType = undefined;
				this.modelRef.cardId = undefined;
				this.exchangeList = [];
				this.modelRef.ticketBatchId = [];
				// this.snackList = [];
				this.modelRef.snackBatchId = [];
				this.getCardList();
			},
			onTabChange(key) {
				let element = document.getElementById(key + '');
				element.scrollIntoView();
			},
			onCardChange(key) {
				this.cardList = [];
				this.modelRef.cardId = undefined;
				if (this.modelRef.cinemaId) {
					this.getCardList();
				} else {
					this.$message.warn('请选择所属影院');
				}
			},
			onDelUpload(type, info) {
				if (type === 1) {
					this.modelRef.imgUrl = [];
					this.fileList.forEach(item => {
						if (item.url && item.url !== info.url) {
							this.modelRef.imgUrl.push(item.url);
						}
					});
				}
				if (type === 2) {
					this.modelRef.videoUrl = '';
				}
				if (type === 3) {
					this.modelRef.backgroundImg = [];
				}
				return true;
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('上传文件格式不正确');
				}
				const isLt1M = file.size / 1024 / 1024 < 2;
				if (!isLt1M) {
					this.$message.error('上传文件太大了');
				}
				return isJpgOrPng && isLt1M;
			},
			handleChange(info, type) {
				if (!info.file.status) {
					if (type === 1) {
						this.fileList.splice(this.fileList.length - 1, 1);
					}
					if (type === 2) {
						this.shareFileList.splice(this.shareFileList.length - 1, 1);
					}
				}
				if (info.file.status === 'done') {
					// this.fileList = info.fileList;
					if (type === 1) {
						this.modelRef.imgUrl = [];
					}
					if (type === 2) {
						this.modelRef.backgroundImg = [];
					}
					const $fileList = [...info.fileList];
					$fileList.map(file => {
						if (file.response) {
							if (type === 1) {
								this.modelRef.imgUrl.push(file.response.data.imgUrl);
							}
							if (type === 2) {
								this.modelRef.backgroundImg.push(file.response.data.imgUrl);
							}
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			beforeUploadVideo(file) {
				const isMp4 = file.type === 'video/mp4';
				if (!isMp4) {
					this.$message.error('上传文件格式不正确');
				}
				const isLtSize = file.size / 1024 / 1024 < 30;
				if (!isLtSize) {
					this.$message.error('上传文件太大了');
				}
				return isMp4 && isLtSize;
			},
			handleVideoChange(info) {
				if (info.file.status === 'done') {
					this.videoList = info.fileList;
					const $fileList = [...this.videoList];
					this.videoList = $fileList.map(file => {
						if (file.response) {
							this.modelRef.videoUrl = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
			 	});
				}
			},
			onSnackChange(list) {
				this.snackList = JSON.parse(JSON.stringify(list));
			},
			onDelete(item) {
				let index = -1;
				for (let i = 0; i < this.snackList.length; i++) {
					if (this.snackList[i].id === item.id) {
						index = i;
					}
				}
				if (index >= 0) {
					this.snackList.splice(index, 1);
				}
				this.snackIds.splice(this.snackIds.indexOf(item.id), 1);
			},
			onIsCouponChange() {
				this.modelRef.ticketBatchId = [];
				this.modelRef.snackBatchId = [];
				this.exchangeList = [];
				this.snackList = [];
			},
			onCouponChange(type, data) {
				if (type === 1) {
					this.exchangeList = data || [];
				}
				if (type === 3) {
					this.snackList = data || [];
				}
			},
			onDelCoupon(type, index) {
				if (type === 1) {
					this.exchangeList.splice(index, 1);
					this.modelRef.ticketBatchId = [];
				}
				if (type === 3) {
					this.snackList.splice(index, 1);
					this.modelRef.snackBatchId = [];
				}
			},
			transClassify() {
				if (this.modelRef.classifyId && this.modelRef.classifyId.length && this.classifyList.length) {
					let hasId = [];
					this.classifyList.forEach(item => {
						if (this.modelRef.classifyId.includes(item.id)) {
							hasId.push(true);
						}
						if (item.children && item.children.length) {
							item.children.forEach(subItem =>{
								if (this.modelRef.classifyId.includes(subItem.id)) {
									hasId.push(true);
								}
							})
						}
					})
					if (hasId.length !== 2) {
						this.modelRef.classifyId = undefined;
					}
				} else {
					this.modelRef.classifyId = undefined;
				}
			},
			onMaxInput(index, key) {
				// let price = price || 0;
				// this.modelRef.nowPrice
				let count = 0;
				this.list.forEach((item, i) => {
					if (index === i) {
						if (key === 'oneLevelBonus') {
							count = count + Number(item.twoLevelBonus) + Number(item.storekeeperLevelBonus);
						}
						if (key === 'twoLevelBonus') {
							count = count + Number(item.oneLevelBonus) + Number(item.storekeeperLevelBonus);
						}
						if (key === 'storekeeperLevelBonus') {
							count = count + Number(item.oneLevelBonus) + Number(item.twoLevelBonus);
						}
					}
				})
				let max = this.modelRef.nowPrice ? (this.modelRef.nowPrice - 0.01 - count) : (this.modelRef.nowPrice - count);
				if (max >= 0) {
					return Number(max);
				} else {
					if (key) {
						return 0;
					} else {
						return Number(max);
					}
				}
			},
			onMaxScoreInput(index, key) {
				// let price = price || 0;
				// this.modelRef.nowPrice
				let count = 0;
				this.list.forEach((item, i) => {
					if (index === i) {
						if (key === 'oneLevelScale') {
							count = count + Number(item.twoLevelScale) + Number(item.storekeeperLevelScale);
						}
						if (key === 'twoLevelScale') {
							count = count + Number(item.oneLevelScale) + Number(item.storekeeperLevelScale);
						}
						if (key === 'storekeeperLevelScale') {
							count = count + Number(item.oneLevelScale) + Number(item.twoLevelScale);
						}
					}
				});
				let max = 99 - count;
				if (max >= 0) {
					return Number(max);
				} else {
					if (key) {
						return 0;
					} else {
						return Number(max);
					}
				}
			}
		}
	}
</script>

<style scoped>
	.ui-card {
		padding: 0px 30px;
		border-bottom: solid 1px #CCCCCC;
	}

	.ui-card+.ui-card {
		margin-top: 20px;
	}

	.ui-card__header {
		padding-top: 150px;
		margin-top: -150px;
		margin-bottom: 20px;
	}

	.ui-label {
		margin-top: 10px;
		padding: 10px 10px 4px;
		margin-bottom: 30px;
		background-color: #eee;
		border-radius: 10px;
	}
	.ui-label__item {
		height: 28px;
		margin-bottom: 6px;
		font-size: 14px;
		line-height: 28px;
	}
</style>
